.App {
 
  padding: 0.5rem  3.5rem;
  overflow: hidden;
  color: var(--black);
}



.button{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px  26px  11px  26px;
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px  20px  24px  3px  rgba(251, 161, 40,  0.42);
}

.button:hover{
  background: white;
  cursor: pointer;
  color: orange;
  border: 3px solid orange;
}



@media screen  and (max-width: 480px)  {
  .App{
    padding: 0.5rem 1rem;
  
}
}