.works{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 60vh;
    margin-top: 4rem;
    gap: 20rem;
}

/* right side */

.w-right{
    position: relative;
}

.w-mainCircle{
    left: 9rem;
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: 0px 19px 60px rgb(0 0 0 / 8%);
    top: 2rem;
    background: rgb(228, 200, 212);
    
}

.w-mainCircle>*{
    position: absolute;
}

.w-mainCircle> :nth-child(1){
    top: -3rem;
    left: 6rem;
    
}

.w-mainCircle > :nth-child(2){
    left: -3rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(3){
    left: 6rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(4){
    left: 15rem;
    top: 5rem;
}

.w-mainCircle > :nth-child(5){
    left: 6rem;
    top: 13rem;
}


.w-secCircle{
    width: 6rem;
    height: 6rem;
    display: absolute;
    border-radius: 50rem;
    border: 5px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    
    box-shadow: 0px 19px 60px rgb(0 0 0 / 8%);
    background: white;

}

.w-secCircle>img{
height: 11rem;
width: 11rem;
    border-radius: 50%;
}

.w-secCircle > img{
    transform: scale(0.6);
}

.w-backCircle{
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}

.blueCircle{
    background:#194988;
    left: 18rem;
    top:0rem;
}

.yellow{
    background: #F5C32C;
    left: 18rem;
    top: 8rem;
}

@media screen  and ( max-width: 480px) {
    .works{
        flex-direction: column;
        height: 55rem;
        padding: 0;
        gap: 5rem;
    }

    .w-right{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start; 
        transform: scale(0.7);

    }

    .w-mainCircle{
        position: static;
    }

    .w-backCircle{
        left: 8rem!important;
    }

    
}
    
