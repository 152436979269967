.card{
    width: 10rem;
    height: 20rem;
    position: absolute;
    display: flex;
    flex-direction: column;
   
    align-items: center;
   
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid orange;
    box-shadow: 0px 19px 60px rgb(0 0 0 / 8%);
    border-radius: 20px;
    padding: 10px 26px 2px 26px;
}

.card span:nth-of-type(1){
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.card span:nth-of-type(2){
    color: black;
    font-size: 16px;
}

.card>img{
    transform: scale(0.6);
    margin-bottom: -3.5rem;
}



@media screen  and ( max-width: 480px) {
    .card{
        width: auto;
    }
}
    
