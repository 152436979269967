.toggle{
    display: flex;
    justify-content: space-between;
    border: 3px solid orange;
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    cursor: pointer;
}

.toggle>*{
    width: 1rem;
    height: 1rem;
    color: orange;
}

.t-button{
    border-radius: 100%;
    background: orange;
    position: absolute;
}