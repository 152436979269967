.floatingdiv{
    justify-content: space-around;
    display: flex;
    box-shadow: 0px 19px 60px rgb(0 0 0 / 8%);
    background: white;
    border-radius: 17px;
    align-items: center;
    padding: 0px 28px 0px 0px;
    height: 4.5rem;

}

.floatingdiv>img{
transform: scale(0.4);
}

.floatingdiv>span{
    font-family: sans-serif;
    font-size: 16px;
}