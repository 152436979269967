.services{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 7rem;
    margin-bottom: 13rem;
    gap:20rem;
}

.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome>:nth-child(1){
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(2){
    color:orange;
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(3){
    color: black;
    font-size: 18px;
    margin-top: 1rem;
}

.s-button{
    width: 10rem;
    height: 3rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
   
}

.cards>*{
    position: absolute;
    
}


/* Blur */

.s-blur2{
    left: 14rem;
    top: 8rem;
    filter: blur(95px);
}

.s-blur1{
    top:13rem;
    left: -18rem;
    filter: blur(95px);
}

@media screen and (max-width: 480px) {
    .services{
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }

    .cards{
        display: flex;
        flex-direction: column;
        gap:17rem;
    }

    .cards>*{
        position: static;

    }
}
