.t-wrapper{
    padding: 0 3rem 0 3rem;
    height: 100vh;
   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.t-heading{
    align-self: start;
    
}

.t-heading>*{
    font-size: 2rem;
    font-weight: bold;
   
    

}

.t-heading span:nth-of-type(2){
    color: orange;
}

.t-blur1{
    left: 58rem;
    top:20rem;
    filter: blur(99px);
}

.t-blur2{
    top: 9rem;
    left: 0;
}

.testimonial{
    display: flex;
    
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    width: 40rem;
    height: 25rem;
    gap:5rem;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;


}

.testimonial>img{
    width: 12rem;
    height: 22rem;
    border-radius: 2%;
}

.testimonial>span{
    color: var(--gray);
}

/*Slider*/

.t-wrapper .swiper{
    width: 100%;
    height: 70%;
}

.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active{
    background: orange;
}





@media screen and  (max-width: 480px) {
    .t-wrapper{
        padding: 0;
    }

    .testimonial>img{
        width: 70px;
        height: 70px;
    }
}
    
